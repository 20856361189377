import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["BailoutToCSR"] */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager", webpackExports: ["PreloadChunks"] */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/LoadingSpinner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/MajorCitiesSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/UserLocationWeatherCard.tsx");
