"use client";

export default function LoadingSpinner() {
  return (
    <div className="text-center text-gray-500">
      <svg
        className="animate-spin h-5 w-5 mx-auto"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
          fill="none"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="
            M4 12a8 8 0 0 0 8 8V4a8 8 0 0 0-8 8z
          "
        />
      </svg>
    </div>
  );
}
