"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import Script from "next/script";
import { MapPin, Thermometer, Droplet, Wind } from "lucide-react";
import WeatherIcon from "./WeatherIcon";
import { parseRawWeather } from "@/app/lib/weatherParser";

/** -----------------------------------------
 * 1) Types
----------------------------------------- */
interface City {
  name: string;
  slug: string;
}

export interface WeatherDataForDisplay {
  temperature_celsius: number;
  relative_humidity_percent: number;
  wind_speed_m_s: number;
  weather_type: string;
  icon: string;
}

type CityWeatherResult = [string, WeatherDataForDisplay | null];

// New type for the map weather API response
interface MapWeatherResponse {
  [citySlug: string]: {
    place_name_en: string;
    place_name_el: string;
    country_name_en: string;
    country_name_el: string;
    place_slug: string;
    country_slug: string;
    country_code: string;
    latitude: number;
    longitude: number;
    elevation: number;
    admin_divisions: Array<any>;
    full_url_path: string;
    weather_data: Array<{
      forecast_datetime: string;
      run_date: string;
      utc_cycle_time: string;
      forecast_data: Record<string, any>;
    }>;
    num_records: number;
    language: string;
    data_version: string;
    temp_adjustment_info: {
      method: string;
      rate: number;
      unit: string;
    };
  } | null;
}

/** -----------------------------------------
 * 2) The 5 specific cities
----------------------------------------- */
const majorCities: City[] = [
  { name: "Αθήνα", slug: "athens-gr-3797-2373" },
  { name: "Θεσσαλονίκη", slug: "thessaloniki-gr-4064-2293" },
  { name: "Πάτρα", slug: "patras-gr-3824-2173" },
  { name: "Ηράκλειο", slug: "heraklion-gr-3533-2513" },
  { name: "Λάρισα", slug: "larissa-gr-3963-2241" },
];

/** -----------------------------------------
 * 3) The main client component
----------------------------------------- */
export default function MajorCitiesSection() {
  const [cityWeather, setCityWeather] = useState<
    Record<string, WeatherDataForDisplay | undefined>
  >({});
  const [isLoading, setIsLoading] = useState(true);

  // For kairos.gr site, use kairos.gr API
  // If API_URL is set, use that, otherwise use relative path
  const API_BASE = process.env.NEXT_PUBLIC_API_URL || "";
  console.log("MajorCitiesSection - Using API_BASE:", API_BASE);

  /**
   * fetchAllCitiesWeather:
   * Calls the bulk map endpoint to get weather data for all cities at once
   * POST /api/weather/map/?closest_hour=1
   */
  async function fetchAllCitiesWeather(): Promise<Record<string, WeatherDataForDisplay | undefined>> {
    try {
      // Extract the slugs from our city list
      const citySlugs = majorCities.map(city => city.slug);

      // Construct the URL for the bulk API endpoint
      const mapApiUrl = `${API_BASE}/api/weather/map/?closest_hour=1`;

      console.log("Making POST request to:", mapApiUrl, "with slugs:", citySlugs);
      // Make the POST request with the slugs in the request body
      const response = await fetch(mapApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ slugs: citySlugs }),
        cache: "no-store"
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response (${response.status}):`, errorText);
        throw new Error(`HTTP error! status: ${response.status}. Response: ${errorText.substring(0, 100)}`);
      }

      // Parse the JSON response
      const mapData: MapWeatherResponse = await response.json();

      // Create a mapping of city name to weather data
      const weatherDataRecord: Record<string, WeatherDataForDisplay | undefined> = {};

      // Process each city
      majorCities.forEach(city => {
        const cityData = mapData[city.slug];

        // If we have data for this city
        if (cityData && cityData.weather_data && cityData.weather_data.length > 0 && cityData.weather_data[0].forecast_data) {
          const forecastData = cityData.weather_data[0].forecast_data;
          console.log(`Processing forecast data for ${city.name}:`, forecastData);

          // Extract the weather data
          const weatherData: WeatherDataForDisplay = {
            temperature_celsius: Math.round((forecastData["2t_level_2"] - 273.15) * 10) / 10,
            relative_humidity_percent: forecastData["2r_level_2"] || 0,
            wind_speed_m_s: Math.sqrt(
              Math.pow(forecastData["10u_level_10"] || 0, 2) +
              Math.pow(forecastData["10v_level_10"] || 0, 2)
            ),
            weather_type: (forecastData.tp_level_0 && forecastData.tp_level_0 > 0.2) ?
              (forecastData["2t_level_2"] - 273.15 < 1 ? "Snow" : "Rain") :
              "Clear",
            icon: (forecastData.tp_level_0 && forecastData.tp_level_0 > 0.2) ?
              (forecastData["2t_level_2"] - 273.15 < 1 ? "snow" : "rain") :
              "sun"
          };

          // Add to our record using the city name as the key (for consistency with the original code)
          weatherDataRecord[city.name] = weatherData;
        } else {
          // No data found for this city
          weatherDataRecord[city.name] = undefined;
        }
      });

      return weatherDataRecord;
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error fetching bulk weather data:`, error.message);
      } else {
        console.error(`Unknown error fetching bulk weather data`, error);
      }
      return {};
    }
  }

  /**
   * On mount, fetch all cities in a single call, then update state.
   * Refresh every 5 minutes.
   */
  useEffect(() => {
    async function fetchWeatherData() {
      try {
        setIsLoading(true);

        // Fetch all city weather data in a single API call
        const weatherDataRecord = await fetchAllCitiesWeather();
        setCityWeather(weatherDataRecord);

      } catch (error) {
        console.error("Error fetching all city weather data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchWeatherData();

    // Refresh every 5 minutes (300000 ms)
    const intervalId = setInterval(fetchWeatherData, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  /** Build city path for <Link> */
  function buildCityPath(citySlug: string) {
    // If you want the actual Next.js route to be /el/weather/greece/<slug>,
    // you can prepend "/el". If you prefer no 'el', remove that part.
    return `/weather/${citySlug}`;
  }

  /** Icon color by type */
  function getWeatherIconColor(weatherType: string) {
    const type = weatherType.toLowerCase();
    if (type.includes("rain")) return "text-blue-600";
    if (type.includes("snow")) return "text-blue-400";
    if (type.includes("clear")) return "text-yellow-500";
    return "text-gray-500";
  }

  /** JSON-LD for site nav */
  const siteNavJsonLd = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: majorCities.map((city, index) => ({
      "@type": "SiteNavigationElement",
      position: index + 1,
      name: city.name,
      url: "https://kairos.gr" + buildCityPath(city.slug),
    })),
  };

  /** Render */
  return (
    <div>
      {/* JSON-LD for site nav */}
      <Script
        id="major-cities-site-nav"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(siteNavJsonLd) }}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {majorCities.map((city) => {
          const weather = cityWeather[city.name];

          return (
            <Link
              key={city.name}
              href={buildCityPath(city.slug)}
              className="block group"
            >
              <div className="bg-sky-200 rounded-xl border border-gray-200 p-6 shadow-sm hover:shadow-md transition-all duration-200">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center space-x-3">
                    <WeatherIcon
                      weatherType={weather?.weather_type || "Clear"}
                      isDaytime
                      size="sm"
                      className={getWeatherIconColor(
                        weather?.weather_type || "clear"
                      )}
                    />
                    <h3 className="text-base font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                      {city.name}
                    </h3>
                  </div>
                  <MapPin className="w-5 h-5 text-gray-400 group-hover:text-blue-500 transition-colors" />
                </div>

                {/* isLoading => spinner, else => show weather or fallback */}
                {isLoading ? (
                  <div className="h-16 flex items-center justify-center">
                    <div className="animate-pulse flex space-x-2">
                      <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
                      <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
                      <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
                    </div>
                  </div>
                ) : weather ? (
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Thermometer className="w-5 h-5 text-blue-500" />
                      <span className="text-2xl font-bold text-blue-600">
                        {Math.round(weather.temperature_celsius)}°C
                      </span>
                    </div>
                    <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
                      <div className="flex items-center space-x-2">
                        <Droplet className="w-4 h-4" />
                        <span>
                          {Math.round(weather.relative_humidity_percent)}%
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <Wind className="w-4 h-4" />
                        <span>
                          {weather.wind_speed_m_s.toFixed(1)} m/s
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="h-16 flex items-center justify-center text-gray-500">
                    Data unavailable
                  </div>
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}