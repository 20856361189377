/**
 * Site configuration utility for managing site-specific settings
 */

// Function to determine if we're running on kairos.gr
export const isKairosSite = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname.includes('kairos.gr');
  }
  
  // Default for server-side rendering
  // Use environment variable if available, otherwise assume kairos.gr for this build
  return process.env.NEXT_PUBLIC_SITE_DOMAIN?.includes('wfy24.com') === false;
};

// Get the site domain (useful for canonical URLs)
export const getSiteDomain = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname;
  }
  return process.env.NEXT_PUBLIC_SITE_DOMAIN || 'kairos.gr';
};

// Get the base URL for the site (including protocol)
export const getBaseUrl = () => {
  const domain = getSiteDomain();
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  }
  return `https://${domain}`;
};

// Get the API URL for backend requests
export const getApiUrl = () => {
  if (process.env.NEXT_PUBLIC_API_URL) {
    return process.env.NEXT_PUBLIC_API_URL;
  }
  
  const domain = getSiteDomain();
  if (domain.includes('kairos.gr')) {
    return 'https://kairos.gr/api';
  } else if (domain.includes('wfy24.com')) {
    return 'https://wfy24.com/api';
  }
  
  // Default for development
  return 'http://localhost:8000/api';
};

// Get the site language code
export const getSiteLanguage = () => {
  const domain = getSiteDomain();
  if (domain.includes('kairos.gr')) {
    return 'el'; // Greek for kairos.gr
  }
  return 'en'; // English for all other sites (wfy24.com)
};

// Override language for testing specific translations
export const getRequestLanguage = (langParam?: string) => {
  if (langParam && (langParam === 'el' || langParam === 'en')) {
    return langParam;
  }
  return getSiteLanguage();
};

// Get site metadata
export const getSiteMetadata = () => {
  const isKairos = isKairosSite();
  
  if (isKairos) {
    return {
      siteName: 'kairos.gr',
      title: 'Καιρός - kairos.gr',
      description: 'Πρόγνωση καιρού για την Ελλάδα και όλο τον κόσμο',
      locale: 'el_GR',
    };
  }
  
  return {
    siteName: 'wfy24.com',
    title: 'Weather for You 24',
    description: 'Weather forecasts worldwide',
    locale: 'en_US',
  };
};