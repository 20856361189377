"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { Droplet, Wind, MapPin, Gauge, Clock } from "lucide-react";
import WeatherIcon from "./WeatherIcon";
import { t } from "@/app/lib/i18n";
// Removed unused Script import
// Removed useClientNonce as nonce is not used

// Interface for weather data
export interface WeatherDataForDisplay {
  temperature_celsius: number;
  relative_humidity_percent: number;
  wind_speed_m_s: number;
  weather_type: string;
  icon: string;
  pressure_hPa?: number;
}

// Location data from geolocation
interface LocationData {
  id: number;
  guid: string | null;
  slug: string;
  name: string;
  latitude: number;
  longitude: number;
  elevation: number;
  description: string;
  country: {
    code: string;
    name: string;
    slug: string;
  };
  seo_url: string;
  category: {
    id: number;
    name: string;
  };
  // Keep backward compatibility with existing stored data
  place_name_en?: string;
  place_name_el?: string;
  country_name_en?: string;
  country_name_el?: string;
  place_slug?: string;
  country_slug?: string;
  country_code?: string;
  admin_divisions?: Array<{
    level: number;
    level_name_en: string;
    level_name_el: string;
    division_slug: string;
    division_name: string;
    division_name_en: string;
    division_name_el: string;
    url_order: number;
  }>;
}

export default function UserLocationWeatherCard() {
  const [locationData, setLocationData] = useState<LocationData | null>(null);
  const [weatherData, setWeatherData] = useState<WeatherDataForDisplay | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  // Removed unused nonce variable

  // Helper function to get the URL for a location
  const getWeatherUrl = (location: LocationData): string => {
    // If we have a direct seo_url
    if (location.seo_url) {
      // Remove trailing slash if present and ensure format is /weather/slug, not /weather/country/slug
      return location.seo_url.replace(/\/$/, '').replace(/\/weather\/[^\/]+\//, '/weather/');
    }

    // If no seo_url, construct it from slug
    return `/weather/${location.slug || location.place_slug}`;
  };

  // Helper function to get the geography URL for a location
  const getGeographyUrl = (location: LocationData): string => {
    // If we have a direct seo_url for geography
    if (location.seo_url && location.seo_url.includes('/geography/')) {
      // Remove trailing slash if present
      return location.seo_url.replace(/\/$/, '');
    }
    
    // Always use simple URL structure
    return `/geography/${location.slug || location.place_slug}`;
  };

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  // Fetch weather data for the user's saved location with retry and caching
  const fetchWeatherForLocation = async (slug: string) => {
    try {
      setIsLoading(true);
      setHasError(false);
      
      // First try to get from localStorage cache for instant response
      const weatherCacheKey = `weather_data_${slug}`;
      const cachedWeatherStr = localStorage.getItem(weatherCacheKey);
      const cacheExpiry = localStorage.getItem(`${weatherCacheKey}_expiry`);
      
      // Check if we have valid cached data (less than 5 minutes old)
      if (cachedWeatherStr && cacheExpiry) {
        try {
          const expiryTime = parseInt(cacheExpiry, 10);
          if (Date.now() < expiryTime) {
            // Cache is still valid
            const cachedWeather = JSON.parse(cachedWeatherStr);
            setWeatherData(cachedWeather);
            setIsLoading(false);
            
            // Do a background refresh but don't block UI
            refreshWeatherDataInBackground(slug, weatherCacheKey);
            return;
          }
        } catch (e) {
          console.error("Error parsing cached weather data:", e);
          // Continue with API request
        }
      }

      // Set API base URL
      const API_BASE = process.env.NEXT_PUBLIC_API_URL || "";
      console.log("UserLocationWeatherCard - Using API base URL:", API_BASE);

      // Construct the URL for the weather API endpoint
      const mapApiUrl = `${API_BASE}/api/weather/map/?closest_hour=1`;
      
      // Implement retry mechanism
      const MAX_RETRIES = 3;
      let attempt = 0;
      let success = false;
      let lastError: Error | null = null;
      
      while (attempt < MAX_RETRIES && !success) {
        try {
          console.log(`Fetching weather data for ${slug} (attempt ${attempt + 1}/${MAX_RETRIES})`);
          
          // Make the POST request with the slug in the request body
          const response = await fetch(mapApiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
            },
            body: JSON.stringify({ slugs: [slug] }),
            next: { 
              revalidate: 300 // 5 minutes 
            }
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error(`API Error Response (${response.status}):`, errorText);
            throw new Error(`HTTP error! status: ${response.status}. Response: ${errorText.substring(0, 100)}`);
          }

          // Parse the JSON response
          const mapData = await response.json();
          const placeData = mapData[slug];

          if (!placeData || !placeData.weather_data || placeData.weather_data.length === 0) {
            throw new Error("No weather data available for this location");
          }

          // Extract the forecast data
          const forecastData = placeData.weather_data[0].forecast_data;

          // Create the weather data object
          const weather: WeatherDataForDisplay = {
            temperature_celsius: Math.round((forecastData["2t_level_2"] - 273.15) * 10) / 10,
            relative_humidity_percent: forecastData["2r_level_2"] || 0,
            wind_speed_m_s: Math.sqrt(
              Math.pow(forecastData["10u_level_10"] || 0, 2) +
              Math.pow(forecastData["10v_level_10"] || 0, 2)
            ),
            weather_type: forecastData.tp_level_0 > 0.2 ?
              (forecastData["2t_level_2"] - 273.15 < 1 ? "Snow" : "Rain") :
              "Clear",
            icon: forecastData.tp_level_0 > 0.2 ?
              (forecastData["2t_level_2"] - 273.15 < 1 ? "snow" : "rain") :
              "sun",
            pressure_hPa: (forecastData.prmsl_level_0 && forecastData.prmsl_level_0 > 5000 ? Math.round(forecastData.prmsl_level_0 / 100) : Math.round(forecastData.prmsl_level_0)) || 
              (forecastData.sp_surface && forecastData.sp_surface > 5000 ? Math.round(forecastData.sp_surface / 100) : Math.round(forecastData.sp_surface)) || 
              undefined
          };

          // Cache the weather data in localStorage
          localStorage.setItem(weatherCacheKey, JSON.stringify(weather));
          // Set cache expiry to 5 minutes
          localStorage.setItem(`${weatherCacheKey}_expiry`, (Date.now() + 5 * 60 * 1000).toString());
          
          setWeatherData(weather);
          success = true;
          break; // Exit the retry loop
          
        } catch (err) {
          lastError = err as Error;
          attempt++;
          console.error(`Attempt ${attempt}/${MAX_RETRIES} failed:`, err);
          
          // Only wait if we're going to retry
          if (attempt < MAX_RETRIES) {
            // Exponential backoff: 500ms, 1000ms, etc.
            const delay = 500 * Math.pow(2, attempt - 1);
            console.log(`Waiting ${delay}ms before retry...`);
            await new Promise(r => setTimeout(r, delay));
          }
        }
      }
      
      // If all retries failed
      if (!success) {
        console.error("All attempts to fetch weather data failed", lastError);
        setHasError(true);
      }
      
    } catch (error) {
      console.error("Error fetching weather data:", error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Function to refresh weather data in background without blocking UI
  const refreshWeatherDataInBackground = async (slug: string, cacheKey: string) => {
    try {
      const API_BASE = process.env.NEXT_PUBLIC_API_URL || "";
      const mapApiUrl = `${API_BASE}/api/weather/map/?closest_hour=1`;
      
      const response = await fetch(mapApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({ slugs: [slug] }),
      });

      if (!response.ok) {
        return; // Silently fail for background refresh
      }

      const mapData = await response.json();
      const placeData = mapData[slug];

      if (!placeData?.weather_data?.length) {
        return;
      }

      const forecastData = placeData.weather_data[0].forecast_data;
      const weather: WeatherDataForDisplay = {
        temperature_celsius: Math.round((forecastData["2t_level_2"] - 273.15) * 10) / 10,
        relative_humidity_percent: forecastData["2r_level_2"] || 0,
        wind_speed_m_s: Math.sqrt(
          Math.pow(forecastData["10u_level_10"] || 0, 2) +
          Math.pow(forecastData["10v_level_10"] || 0, 2)
        ),
        weather_type: forecastData.tp_level_0 > 0.2 ?
          (forecastData["2t_level_2"] - 273.15 < 1 ? "Snow" : "Rain") :
          "Clear",
        icon: forecastData.tp_level_0 > 0.2 ?
          (forecastData["2t_level_2"] - 273.15 < 1 ? "snow" : "rain") :
          "sun",
        pressure_hPa: (forecastData.prmsl_level_0 && forecastData.prmsl_level_0 > 5000 ? Math.round(forecastData.prmsl_level_0 / 100) : Math.round(forecastData.prmsl_level_0)) || 
          (forecastData.sp_surface && forecastData.sp_surface > 5000 ? Math.round(forecastData.sp_surface / 100) : Math.round(forecastData.sp_surface)) || 
          undefined
      };

      // Update cache
      localStorage.setItem(cacheKey, JSON.stringify(weather));
      localStorage.setItem(`${cacheKey}_expiry`, (Date.now() + 5 * 60 * 1000).toString());
      
      // Update UI state
      setWeatherData(weather);
      
    } catch (error) {
      console.error("Background refresh error:", error);
      // Fail silently - user still has cached data
    }
  };

  // Load saved location data from localStorage on mount
  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedLocation = localStorage.getItem("physicalLocationData");
      if (savedLocation) {
        try {
          const locationInfo = JSON.parse(savedLocation) as LocationData;
          setLocationData(locationInfo);

          // Fetch weather for this location
          const slug = locationInfo.slug || locationInfo.place_slug;
          if (slug) {
            fetchWeatherForLocation(slug);
          } else {
            setIsLoading(false);
            setHasError(true);
          }
        } catch (error) {
          console.error("Error parsing location data:", error);
          setIsLoading(false);
          setHasError(true);
        }
      } else {
        // No saved location
        setIsLoading(false);
      }
    }

    // Listen for placeDataChanged events
    const handlePlaceDataChanged = () => {
      const updatedLocation = localStorage.getItem("physicalLocationData");
      if (updatedLocation) {
        try {
          const locationInfo = JSON.parse(updatedLocation) as LocationData;
          setLocationData(locationInfo);

          // Fetch weather for this location
          const slug = locationInfo.slug || locationInfo.place_slug;
          if (slug) {
            fetchWeatherForLocation(slug);
          }
        } catch (error) {
          console.error("Error parsing updated location data:", error);
        }
      }
    };

    window.addEventListener("placeDataChanged", handlePlaceDataChanged);

    // Clean up event listener
    return () => {
      window.removeEventListener("placeDataChanged", handlePlaceDataChanged);
    };
  }, []);

  // If no location data is saved yet
  if (!locationData) {
    return null;
  }

  // Helper function to determine if it's night time
  const isNight = () => {
    const hour = currentTime.getHours();
    return hour < 6 || hour >= 20;
  }

  // Helper function to get background gradient based on weather and time
  const getBgGradient = () => {
    if (isNight()) {
      return weatherData?.weather_type.includes("Rain") || weatherData?.weather_type.includes("Snow")
        ? "from-gray-900 via-blue-900 to-gray-800"
        : "from-indigo-900 via-blue-900 to-purple-900";
    } else {
      return weatherData?.weather_type.includes("Rain")
        ? "from-blue-700 via-blue-600 to-blue-800"
        : weatherData?.weather_type.includes("Snow")
          ? "from-blue-600 via-blue-500 to-indigo-700"
          : "from-sky-500 via-blue-500 to-indigo-600";
    }
  };

  // Format time for display
  const formatTime = () => {
    return currentTime.toLocaleTimeString("en", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      hourCycle: "h23"
    });
  };

  return (
    <section aria-labelledby="your-location-heading" className="mb-6">
      <h2 id="your-location-heading" className="text-xl font-bold mb-3 text-gray-900">
        {t('weatherAtYourLocation')}
      </h2>

      <div className={`bg-gradient-to-br ${getBgGradient()} rounded-3xl shadow-2xl overflow-hidden transition-all duration-500`}>
        <div className="p-6">
          {/* Header with Location Name and Current Time */}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
            <div className="flex items-center gap-3">
              <h3 className="text-2xl font-bold text-white">
                {locationData.name || (document.documentElement.lang === "el"
                  ? locationData.place_name_el
                  : locationData.place_name_en)}
              </h3>
              <Link
                href={getGeographyUrl(locationData)}
                className="inline-flex items-center justify-center bg-white/20 hover:bg-white/30 rounded-full p-2 transition-colors duration-200"
                title="View geographic details"
                aria-label={`View geographic details for your location`}
              >
                <MapPin className="w-4 h-4 text-white" />
              </Link>
            </div>
            <div className="flex items-center gap-2 text-white/90 text-sm">
              <Clock className="w-4 h-4" />
              <span>
                {currentTime.toLocaleDateString("en", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                })}, {formatTime()}
              </span>
            </div>
          </div>

          {/* Weather Conditions Banner */}
          {weatherData && (
            <div className="mb-6 bg-white/10 backdrop-blur-sm rounded-xl p-3 flex items-center gap-3">
              <div style={{ width: '24px' }}>
                <WeatherIcon
                  weatherType={weatherData.weather_type}
                  isDaytime={!isNight()}
                  size="sm"
                />
              </div>
              <p className="text-white font-medium">
                {weatherData.weather_type === "Clear" ? "Clear conditions" :
                 weatherData.weather_type === "Rain" ? "Rainy conditions" :
                 weatherData.weather_type === "Snow" ? "Snowfall" : "Clear conditions"}
              </p>
            </div>
          )}

          {/* Weather content without the min-height constraint */}
          <div>
            {isLoading ? (
              <div className="h-64 flex items-center justify-center">
                <div className="animate-pulse flex space-x-2">
                  <div className="h-4 w-4 bg-white rounded-full"></div>
                  <div className="h-4 w-4 bg-white rounded-full"></div>
                  <div className="h-4 w-4 bg-white rounded-full"></div>
                </div>
              </div>
            ) : hasError ? (
              <div className="text-center text-white p-8">
                <p>No weather data available</p>
              </div>
            ) : weatherData ? (
              <>
                {/* Current Temperature & Weather Type - Large Display */}
                <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
                  <div className="flex items-center gap-6 mb-6 sm:mb-0">
                    <div style={{ width: '80px', height: '80px' }} className="flex items-center justify-center">
                      <WeatherIcon
                        weatherType={weatherData.weather_type}
                        isDaytime={!isNight()}
                        size="lg"
                      />
                    </div>
                    <div className="text-7xl font-bold text-white h-[80px] flex items-center">
                      {Math.round(weatherData.temperature_celsius)}°
                    </div>
                  </div>
                  <div className="text-xl text-white/90 uppercase tracking-wide">
                    {weatherData.weather_type}
                  </div>
                </div>

                {/* Weather Details Cards - Responsive Grid with fixed aspect ratio */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
                  {/* Humidity Card */}
                  <div className="bg-white/15 backdrop-blur-sm rounded-xl p-5 hover:bg-white/20 transition-colors duration-300 border border-white/10">
                    <div className="flex items-center justify-between mb-3">
                      <span className="text-blue-100 font-medium">{t('humidity')}</span>
                      <Droplet className="w-5 h-5 text-white" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="text-3xl font-bold text-white">
                        {Math.round(weatherData.relative_humidity_percent)}%
                      </div>
                      <div className="text-blue-100 text-sm">
                        {t('humidity')}
                      </div>
                    </div>
                  </div>

                  {/* Wind Card */}
                  <div className="bg-white/15 backdrop-blur-sm rounded-xl p-5 hover:bg-white/20 transition-colors duration-300 border border-white/10">
                    <div className="flex items-center justify-between mb-3">
                      <span className="text-blue-100 font-medium">{t('wind')}</span>
                      <Wind className="w-5 h-5 text-white" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="text-3xl font-bold text-white">
                        {weatherData.wind_speed_m_s.toFixed(1)} <span className="text-xl">m/s</span>
                      </div>
                      <div className="text-blue-100 text-sm">
                        {weatherData.wind_speed_m_s < 0.3 ? "Νηνεμία" :
                        weatherData.wind_speed_m_s < 1.6 ? "Σχεδόν νηνεμία" :
                        weatherData.wind_speed_m_s < 3.4 ? "Πολύ ασθενής" :
                        weatherData.wind_speed_m_s < 5.5 ? "Ασθενής" :
                        weatherData.wind_speed_m_s < 8.0 ? "Μέτριος" :
                        weatherData.wind_speed_m_s < 10.8 ? "Ισχυρός" : "Πολύ ισχυρός"}
                      </div>
                    </div>
                  </div>

                  {/* Pressure Card */}
                  <div className="bg-white/15 backdrop-blur-sm rounded-xl p-5 hover:bg-white/20 transition-colors duration-300 border border-white/10">
                    <div className="flex items-center justify-between mb-3">
                      <span className="text-blue-100 font-medium">{t('pressure')}</span>
                      <Gauge className="w-5 h-5 text-white" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="text-3xl font-bold text-white">
                        {weatherData.pressure_hPa ? weatherData.pressure_hPa : "—"}
                        <span className="text-xl ml-1">hPa</span>
                      </div>
                      <div className="text-blue-100 text-sm">
                        {t('barometricPressure')}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          {/* Link to detailed forecast */}
          <div className="mt-6 text-right">
            <Link
              href={getWeatherUrl(locationData)}
              className="inline-flex items-center text-white/90 hover:text-white font-medium transition-colors"
            >
              Αναλυτική πρόγνωση
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}