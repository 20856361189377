/**
 * Simple i18n utility for handling translations
 */
import { getSiteLanguage } from './siteConfig';

interface TranslationMap {
  [key: string]: {
    el: string;
    en: string;
  };
}

// Basic translations for commonly used strings
const translations: TranslationMap = {
  weather: {
    el: 'Καιρός',
    en: 'Weather',
  },
  forecast: {
    el: 'Πρόγνωση',
    en: 'Forecast',
  },
  temperature: {
    el: 'Θερμοκρασία',
    en: 'Temperature',
  },
  precipitation: {
    el: 'Βροχή',
    en: 'Precipitation',
  },
  wind: {
    el: 'Άνεμος',
    en: 'Wind',
  },
  humidity: {
    el: 'Υγρασία',
    en: 'Humidity',
  },
  pressure: {
    el: 'Πίεση',
    en: 'Pressure',
  },
  barometricPressure: {
    el: 'Βαρομετρική πίεση',
    en: 'Barometric pressure',
  },
  sunrise: {
    el: 'Ανατολή',
    en: 'Sunrise',
  },
  sunset: {
    el: 'Δύση',
    en: 'Sunset',
  },
  daylightDuration: {
    el: 'Διάρκεια φωτός ημέρας',
    en: 'Daylight duration',
  },
  hours: {
    el: 'ώρες',
    en: 'hours',
  },
  minutes: {
    el: 'λεπτά',
    en: 'minutes',
  },
  highTemp: {
    el: 'Μέγιστη',
    en: 'High',
  },
  lowTemp: {
    el: 'Ελάχιστη',
    en: 'Low',
  },
  feelsLike: {
    el: 'Αίσθηση',
    en: 'Feels Like',
  },
  home: {
    el: 'Αρχική',
    en: 'Home',
  },
  search: {
    el: 'Αναζήτηση',
    en: 'Search',
  },
  today: {
    el: 'Σήμερα',
    en: 'Today',
  },
  tomorrow: {
    el: 'Αύριο',
    en: 'Tomorrow',
  },
  hourlyForecast: {
    el: 'Ωριαία Πρόγνωση',
    en: 'Hourly Forecast',
  },
  dailyForecast: {
    el: 'Ημερήσια Πρόγνωση',
    en: 'Daily Forecast',
  },
  morning: {
    el: 'Πρωί',
    en: 'Morning',
  },
  afternoon: {
    el: 'Μεσημέρι',
    en: 'Afternoon',
  },
  evening: {
    el: 'Απόγευμα',
    en: 'Evening',
  },
  night: {
    el: 'Νύχτα',
    en: 'Night',
  },
  weatherIn: {
    el: 'Καιρός στην',
    en: 'Weather in',
  },
  weatherAtYourLocation: {
    el: 'Καιρός στην Τοποθεσία σας',
    en: 'Weather at Your Location',
  },
  majorCities: {
    el: 'Μεγάλες Πόλεις',
    en: 'Major Cities',
  },
  detailedForecast: {
    el: 'Αναλυτική πρόγνωση',
    en: 'Detailed forecast',
  },
  searchPlaceholder: {
    el: 'Αναζήτηση τοποθεσίας...',
    en: 'Search for a location...',
  },
  locationNotFound: {
    el: 'Η τοποθεσία δεν βρέθηκε',
    en: 'Location not found',
  },
  loading: {
    el: 'Φόρτωση...',
    en: 'Loading...',
  },
  errorLoading: {
    el: 'Σφάλμα φόρτωσης',
    en: 'Error loading',
  },
  noResults: {
    el: 'Δεν βρέθηκαν αποτελέσματα',
    en: 'No results found',
  },
  noDataAvailable: {
    el: 'Δεν υπάρχουν διαθέσιμα δεδομένα',
    en: 'No data available',
  },
  // Wind strength descriptions
  calm: {
    el: 'Νηνεμία',
    en: 'Calm',
  },
  nearlyClaim: {
    el: 'Σχεδόν νηνεμία',
    en: 'Nearly calm',
  },
  veryLight: {
    el: 'Πολύ ασθενής',
    en: 'Very light',
  },
  light: {
    el: 'Ασθενής',
    en: 'Light',
  },
  moderate: {
    el: 'Μέτριος',
    en: 'Moderate',
  },
  strong: {
    el: 'Ισχυρός',
    en: 'Strong',
  },
  veryStrong: {
    el: 'Πολύ ισχυρός',
    en: 'Very strong',
  },
  // Footer translations
  aboutUs: {
    el: 'Σχετικά με εμάς',
    en: 'About Us',
  },
  termsOfUse: {
    el: 'Όροι Χρήσης',
    en: 'Terms of Use',
  },
  privacyPolicy: {
    el: 'Πολιτική Απορρήτου',
    en: 'Privacy Policy',
  },
  weatherResources: {
    el: 'Πηγές Καιρού',
    en: 'Weather Resources',
  },
  weatherMaps: {
    el: 'Χάρτες Καιρού',
    en: 'Weather Maps',
  },
  radar: {
    el: 'Ραντάρ',
    en: 'Radar',
  },
  climateData: {
    el: 'Κλιματικά Δεδομένα',
    en: 'Climate Data',
  },
  followUs: {
    el: 'Ακολουθήστε μας',
    en: 'Follow Us',
  },
  contactUs: {
    el: 'Επικοινωνία',
    en: 'Contact Us',
  },
  contactForm: {
    el: 'Φόρμα Επικοινωνίας',
    en: 'Contact Form',
  },
  email: {
    el: 'Email',
    en: 'Email',
  },
  allRightsReserved: {
    el: 'Όλα τα δικαιώματα διατηρούνται',
    en: 'All rights reserved',
  },
  weatherDisclaimer: {
    el: 'Τα δεδομένα καιρού παρέχονται από αναγνωρισμένες μετεωρολογικές υπηρεσίες. Το Kairos.gr δεν ευθύνεται για αποφάσεις που λαμβάνονται με βάση αυτές τις πληροφορίες.',
    en: 'Weather data provided by recognized meteorological services. Kairos.gr is not responsible for decisions made based on this information.',
  },
};

/**
 * Get translation for a key based on current site language
 */
export const t = (key: string): string => {
  const language = getSiteLanguage();
  
  if (!translations[key]) {
    console.warn(`Missing translation for key: ${key}`);
    return key;
  }
  
  return translations[key][language] || translations[key]['en'] || key;
};

/**
 * Format a date according to the current site language
 */
export const formatDate = (date: Date): string => {
  const language = getSiteLanguage();
  
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  
  try {
    return new Intl.DateTimeFormat(language === 'el' ? 'el-GR' : 'en-US', options).format(date);
  } catch (error) {
    console.error('Error formatting date:', error);
    return date.toDateString();
  }
};

/**
 * Format a time according to the current site language
 */
export const formatTime = (date: Date): string => {
  const language = getSiteLanguage();
  
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  
  try {
    return new Intl.DateTimeFormat(language === 'el' ? 'el-GR' : 'en-US', options).format(date);
  } catch (error) {
    console.error('Error formatting time:', error);
    return date.toTimeString().slice(0, 5);
  }
};